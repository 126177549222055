import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { UserService } from 'src/app/services/user.service';
import { DeviceService } from 'src/app/services/device.service';
import { Subscription } from 'rxjs';
import { MqttService, IMqttMessage } from 'ngx-mqtt';

/**
 * for transfering events between components
 */
// https://stackblitz.com/edit/angular-cy6e69?file=src%2Fapp%2Fsecond-component%2Fsecond.component.ts
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  is_admin: boolean = false;
  unread_count: any;
  device: any;
  private subscription: Subscription;


  constructor(
    private authService: AuthService,
    private userService: UserService,
    private deviceService: DeviceService,
    private _mqttService: MqttService,
  ) { }

  ngOnInit() {
    if (this.authService.isAdmin() === 'true') this.is_admin = true;
    else this.is_admin = false;

    this.userService.getNotificationCount()
      .subscribe((data: any) => {
        this.unread_count = data.unread_count;
      },
        (err) => {
          console.log(err);
        }
      );

    this.deviceService.getUserDevices()
      .subscribe((data: any) => {
        if (data.length) this.device = data[0];
      },
        (err) => {
          console.log(err);
        }
      );

    this.subscription = this._mqttService
      .observe('/notifications/' + localStorage.getItem('user_id'))
      .subscribe((message: IMqttMessage) => {
        var new_message = message.payload.toString();
      });

  }
}
