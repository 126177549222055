import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class DeviceService {
    private device_url = environment.api_url + '/users/devices/';
    constructor(
        private http: HttpClient,
    ) { }

    getUserDevices() {
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get<any>(this.device_url, { headers });
    }

    getDeviceByID(id) {
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get<any>(this.device_url + id, { headers });
    }
}
