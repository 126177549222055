import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.scss']
})
export class NotificationsComponent implements OnInit {
  notifications: any;

  constructor(
    private userService: UserService,
  ) { }

  ngOnInit() {
    this.userService
      .getNotifications()
      .subscribe((data: any) => {
        this.notifications = data.notifications;

        if (data.notifications)
          this.userService.updateNotifications(data.notifications[0])
            .subscribe(data => { }, err => { console.log(err); })
      },
        (err) => {
          console.log(err);
        }
      );
  }

}
