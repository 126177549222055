import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AdminDeviceService {
    private project_url = environment.api_url + '/admin/projects/';
    private device_url = environment.api_url + '/admin/devices/';
    constructor(
        private http: HttpClient,
    ) { }

    getProjectDevices(project_id) {
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get<any>(this.project_url + project_id, { headers });
    }

    addProjectDevice(data) {
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.post<any>(this.device_url, data, { headers });
    }

    getDviceById(device_id) {
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get<any>(this.device_url + device_id + '/', { headers });
    }

    updateDviceById(device_id, data) {
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.put<any>(this.device_url + device_id + '/', data, { headers });
    }
}
