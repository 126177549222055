import { Component, OnInit } from '@angular/core';
import { Chart } from 'chart.js';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'

import { DataService } from 'src/app/services/data.service';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit {

  LineChart;
  data_came = false;
  sensors;
  sensors_data;
  loading = false;
  no_content = false;

  device: any;
  sensortypes: any[];
  sensortypestitles: any[];

  constructor(
    private dataService: DataService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnInit() {
    this.loading = true;
    this.dataService.getDeviceData(this.route.snapshot.params['id'], "1000000")
      .subscribe((data: any) => {
        this.loading = false;
        if (Object.keys(data).length === 0 && data.constructor === Object) {
          this.no_content = true;
        }
        console.log(data);
        var sensors = Object.keys(data)
        this.sensors = sensors;
        this.sensors_data = data;
      },
        (err) => {
          console.log(err);
        }
      );
  }
  public ngAfterViewChecked() {
    if (!this.data_came && this.sensors) {
      this.data_came = true;
      this.sensors.forEach((sensor, index) => {
        var data = this.sensors_data[sensor].data.map(entry => entry.$numberDecimal)
        var de = document.getElementById(sensor);
        this.LineChart = new Chart(de, {
          type: 'line',
          data: {
            labels: this.sensors_data[sensor].labels,
            datasets: [{
              label: `${sensor} Readings`,
              data: data,
              fill: true,
              lineTension: 0.2,
              borderColor: "blue",
              borderWidth: 1
            }]
          },
          options: {
            title: {
              text: sensor,
              display: true
            },
          }
        });
      });
    }
    // this.LineChart.data.labels.pop();
    // this.LineChart.update();
    // this.LineChart.data.labels.push("label");
    // this.LineChart.update();
  }
}