import { Component, OnInit } from '@angular/core';
import { AdminDeviceService } from 'src/app/services/admin/adminDevice.service';
import { SensorTypeService } from 'src/app/services/admin/sensortypes.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-list-sensor-type',
  templateUrl: './list-sensor-type.component.html',
  styleUrls: ['./list-sensor-type.component.scss']
})
export class ListSensorTypeComponent implements OnInit {
  error;
  sensortypes;
  loading = false;

  constructor(
    private adminDeviceService: AdminDeviceService,
    private sensorTypeService: SensorTypeService,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.getSensortypes();
  }

  getSensortypes() {
    this.sensorTypeService.getSensorTypes()
      .subscribe((data: any) => {
        this.loading = false;
        this.sensortypes = data;
      },
        (err) => {
          console.log(err);
        }
      );
  }

  addSensorType(form: NgForm) {
    this.error = undefined;
    this.sensorTypeService.addSensorTypes(form.value)
      .subscribe((data: any) => {
        this.getSensortypes();
      },
        (err) => {
          this.error = "some error happened. make sure all fields are filled correctly"
          console.log(err);
        }
      );
  }
}
