import { Component, OnInit } from '@angular/core';
import { AdminUserService } from 'src/app/services/admin/admin_user.service';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss']
})
export class UserInfoComponent implements OnInit {
  user;

  constructor(
    private adminUserService: AdminUserService,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.getUser();
  }

  getUser() {
    this.adminUserService.getUser(this.route.snapshot.params['user_id'])
      .subscribe((data: any) => {
        this.user = data;
      },
        (err) => {
          console.log(err);
        }
      );
  }
}
