import { Component, OnInit } from '@angular/core';
import { ProjectService } from 'src/app/services/admin/project.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-list-project',
  templateUrl: './list-project.component.html',
  styleUrls: ['./list-project.component.scss']
})
export class ListProjectComponent implements OnInit {
  projects;
  error;
  constructor(
    private projectService: ProjectService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.getProjects();
  }

  addProject(form: NgForm) {
    this.error = undefined;
    this.projectService.addProjects(form.value)
      .subscribe((data: any) => {
        this.getProjects();
      },
        (err) => {
          this.error = "project name must be unique."
        }
      );
  }

  getProjects() {
    this.projectService.getProjects()
      .subscribe((data: any) => {
        this.projects = data;
      },
        (err) => {
          console.log(err);
        }
      );
  }
}
