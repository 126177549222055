import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'

import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
  is_admin: boolean = false;
  device;

  constructor(
    private authService: AuthService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnInit() {
    if (this.authService.isAdmin() === 'true') {
      this.is_admin = true;

      this.deviceService.getDeviceByID(this.route.firstChild.snapshot.params['id'])
        .subscribe((data: any) => {
          this.device = data;
        },
          (err) => {
            console.log(err);
          }
        );
    }
    else this.is_admin = false;
  }
}