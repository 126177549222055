import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};


@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private authUrl = environment.api_url + '/users';
  constructor(
    private http: HttpClient,
  ) { }

  signup(userData: any) {
    return this.http.post<any>(this.authUrl, userData);
  }

  login(userdata: any) {
    return this.http.post<any>(this.authUrl + '/token/me', userdata);
  }

  logout() {
    this.removeSession();
  }

  setSession(data) {
    localStorage.setItem('id_token', data.token);
    localStorage.setItem('is_admin', data.is_admin);
    localStorage.setItem('user_id', data._id);
  };

  removeSession() {
    localStorage.removeItem('id_token');
  };

  isAuthenticated() {
    if (localStorage.getItem('id_token')) {
      return true;
    } else {
      return false;
    }
  };

  isAdmin() {
    return localStorage.getItem('is_admin');
  };

  decode() {
    return {
      Role: "admin"
    }
  };
}
