import { Injectable } from '@angular/core';
import { AuthService } from './../services/auth.service';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, Route } from '@angular/router';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class RoleGuardService {

  constructor(private _authService: AuthService, private _router: Router) {
  }

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    // const user = this._authService.decode();

    // if (user.Role === next.data.role) {
    //   return true;
    // }

    // // navigate to not found page
    // this._router.navigate(['/login']);
    // return false;
    if (this._authService.isAdmin() === 'true') {
      return true;
    }
    this._router.navigate(['/dashboard/profile']);
    return false;
  }

}