import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private admin_data_url = environment.api_url + '/admin/data/';
  private user_data_url = environment.api_url + '/users/data/';
  constructor(
    private http: HttpClient,
  ) { }

  getDeviceData(device_id, range) {
    var token = `JWT ${localStorage.getItem('id_token')}`
    const headers = new HttpHeaders({ 'Authorization': token });
    if (localStorage.getItem('is_admin') === 'true') {
      return this.http.get<any>(this.admin_data_url + `${device_id}/?range=${range}`, { headers });
    }
    return this.http.get<any>(this.user_data_url + `${device_id}/?range=${range}`, { headers });
  }
}
