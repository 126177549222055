import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { url } from 'inspector';

@Injectable({
    providedIn: 'root'
})
export class UserService {
    private data_url = environment.api_url + '/users/me/';
    constructor(
        private http: HttpClient,
    ) { }

    getMe() {
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get<any>(this.data_url, { headers });
    }

    getNotifications() {
        var url = environment.api_url + '/users/notifications/me/'
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get<any>(url, { headers });
    }

    updateNotifications(createdAt) {
        var url = environment.api_url + '/users/notifications/me/'
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.put<any>(
            url,
            { createdAt },
            { headers }
        );
    }

    getNotificationCount() {
        var url = environment.api_url + '/users/notifications-count/me/'
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get<any>(url, { headers });
    }
}
