import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms'

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {
  error;
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
  };

  onSubmit(form: NgForm) {
    var data = form.value
    if (data.email && data.password) {
      this.authService.signup(data)
        .subscribe((data: any) => {
          console.log(data);
          this.router.navigateByUrl('/check-your-email');
        },
          (err) => {
            this.error = "some error happened"
            console.log(err);
          }
        );
    }
  }

}
