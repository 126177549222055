import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-sensors',
  templateUrl: './list-sensors.component.html',
  styleUrls: ['./list-sensors.component.scss']
})
export class ListSensorsComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
