import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SensorTypeService {
  private sensor_type_url = environment.api_url + '/admin/sensor_types/';
  constructor(
    private http: HttpClient,
  ) { }

  getSensorTypes() {
    var token = `JWT ${localStorage.getItem('id_token')}`
    const headers = new HttpHeaders({ 'Authorization': token });
    return this.http.get<any>(this.sensor_type_url, { headers });
  }

  getSensorType(sensor_type_id) {
    var token = `JWT ${localStorage.getItem('id_token')}`
    const headers = new HttpHeaders({ 'Authorization': token });
    return this.http.get<any>(this.sensor_type_url + sensor_type_id + '/', { headers });
  }

  addSensorTypes(data) {
    var token = `JWT ${localStorage.getItem('id_token')}`
    const headers = new HttpHeaders({ 'Authorization': token });
    return this.http.post<any>(this.sensor_type_url, data, { headers });
  }

  updateSensorTypeById(sensor_type_id, data) {
    var token = `JWT ${localStorage.getItem('id_token')}`
    const headers = new HttpHeaders({ 'Authorization': token });
    return this.http.put<any>(this.sensor_type_url + sensor_type_id + '/', data, { headers });
  }
}
