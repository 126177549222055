import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router'

import { DeviceService } from 'src/app/services/device.service';

import { Subscription } from 'rxjs';
import { MqttService, IMqttMessage } from 'ngx-mqtt';

@Component({
  selector: 'app-realtime',
  templateUrl: './realtime.component.html',
  styleUrls: ['./realtime.component.scss']
})
export class RealtimeComponent implements OnInit, OnDestroy {
  gauss_configurations = [];

  private subscription: Subscription;

  loading = true;
  no_content = false;

  device: any;
  sensortypes: any[];
  sensortypestitles: any[];

  constructor(
    private _mqttService: MqttService,
    private deviceService: DeviceService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.router.navigated = false;
      }
    });
  }

  ngOnInit() {
    this.deviceService.getDeviceByID(this.route.snapshot.params['id'])
      .subscribe((data: any) => {
        this.loading = false;
        this.device = data;
        this.sensortypes = data.sensortypes;
        this.sensortypestitles = this.sensortypes.map(st => st.sensor_type.type);
        this.createGaussConfiguration();

        this.subscription = this._mqttService
          .observe('device/' + this.route.snapshot.params['id'])
          .subscribe((message: IMqttMessage) => {
            var new_message = JSON.parse(message.payload.toString());
            this.updateSensorData(new_message);
          });
      },
        (err) => {
          console.log(err);
        }
      );
  }

  public unsafePublish(topic: string, message: string): void {
    this._mqttService.unsafePublish(topic, message, { qos: 1, retain: true });
  }

  public ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  createGaussConfiguration() {
    this.sensortypes.forEach(st => {
      var configuration = {
        gaugeType: "semi",
        gaugeValue: 0,
        gaugeLabel: st.sensor_type.type_ar,
        gaugeAppendText: st.sensor_type.measurement_unit,
        thresholdConfig: {
          '-1000': { color: 'red' },
        },
        fa_icon: st.sensor_type.fa_icon
      }
      configuration.thresholdConfig[st.lower_limit.$numberDecimal] = {}
      configuration.thresholdConfig[st.upper_limit.$numberDecimal] = { color: 'red' }
      this.gauss_configurations.push(
        configuration
      )
    });
  }

  updateSensorData(data) {
    var index = this.sensortypestitles.indexOf(data.sensor_type)
    if (index !== -1) {
      this.gauss_configurations[index]['gaugeValue'] = data.reading;
    }
  }

}
