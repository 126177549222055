import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms'
import { AdminDeviceService } from 'src/app/services/admin/adminDevice.service';
import { SensorTypeService } from 'src/app/services/admin/sensortypes.service';


@Component({
  selector: 'app-add-device',
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss']
})
export class AddDeviceComponent implements OnInit {
  error;
  sensortypes;
  loading = false;
  constructor(
    private adminDeviceService: AdminDeviceService,
    private SensorTypeService: SensorTypeService,
    private router: Router
  ) { }

  ngOnInit() {
    this.loading = true;
    this.SensorTypeService.getSensorTypes()
      .subscribe((data: any) => {
        this.loading = false;
        this.sensortypes = data;
      },
        (err) => {
          console.log(err);
        }
      );
  }

  onSubmit(form: NgForm) {
    this.error = null;
    var data = form.value
    if (data._owner && data._project && data.sensortypes) {
      data.sensortypes = data.sensortypes.map(sensortype => {
        return { sensor_type: sensortype, upper_limit: 100, lower_limit: 0 }
      });
      this.adminDeviceService.addProjectDevice(data)
        .subscribe((data: any) => {
          this.router.navigateByUrl('/admin/devices/' + data._project);
        },
          (err) => {
            console.log(err);
          }
        );
    } else {
      this.error = "All Fields are required";
    }
  }

}
