import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DeviceService } from 'src/app/services/device.service';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit {
  devices;
  is_admin = false;
  constructor(
    private deviceService: DeviceService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {

    if (this.authService.isAdmin() === 'true') {
      this.is_admin = true;
      return
    }
    this.deviceService.getUserDevices()
      .subscribe((data: any) => {
        if (data.length) this.devices = [data[0]._id];
      },
        (err) => {
          console.log(err);
        }
      );
  }
}
