import { Component, OnInit } from '@angular/core';
import { AdminDeviceService } from 'src/app/services/admin/adminDevice.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-list-devices',
  templateUrl: './list-devices.component.html',
  styleUrls: ['./list-devices.component.scss']
})
export class ListDevicesComponent implements OnInit {
  error;
  project;
  constructor(
    private route: ActivatedRoute,
    private admin_device_service: AdminDeviceService
  ) { }

  ngOnInit() {
    this.getProjectDevices();
  }

  getProjectDevices() {
    this.admin_device_service.getProjectDevices(this.route.snapshot.params['project_id'])
      .subscribe((data: any) => {
        this.project = data;
      },
        (err) => {
          console.log(err);
        }
      );
  }
}
