import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

/**
 * ............. mqtt configurations .............
 * reference:
 * https://sclausen.github.io/ngx-mqtt/#description
 */
import { environment } from '../environments/environment';

import {
  MqttModule,
  IMqttServiceOptions
} from 'ngx-mqtt';


export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = {
  hostname: environment.mqtt_url,
  port: environment.mqtt_port,
  path: ''
};

//end mqtt configurations

import { NgxGaugeModule } from 'ngx-gauge';

import { AppRoutingModule } from './app-routing.module';
/**
 * ............. components .............
 */
import { AppComponent } from './app.component';
import { HeaderComponent } from './views/header/header.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { RealtimeComponent } from './views/dashboard/realtime/realtime.component';
import { HistoryComponent } from './views/dashboard/history/history.component';
import { SidebarComponent } from './views/sidebar/sidebar.component';
import { AuthenticateComponent } from './views/user/authenticate/authenticate.component';
import { LoginComponent } from './views/user/login/login.component';
import { SignupComponent } from './views/user/signup/signup.component';
import { ProfileComponent } from './views/user/profile/profile.component';
import { AddDeviceComponent } from './views/admin/device/add-device/add-device.component';
import { EditDeviceComponent } from './views/admin/device/edit-device/edit-device.component';
import { ListDevicesComponent } from './views/admin/device/list-devices/list-devices.component';
import { ListProjectComponent } from './views/admin/project/list-project/list-project.component';
import { AddSensorComponent } from './views/admin/sensor/add-sensor/add-sensor.component';
import { EditSensorComponent } from './views/admin/sensor/edit-sensor/edit-sensor.component';
import { ListSensorsComponent } from './views/admin/sensor/list-sensors/list-sensors.component';
import { AddUserComponent } from './views/admin/user/add-user/add-user.component';
import { EditUserComponent } from './views/admin/user/edit-user/edit-user.component';
import { ListUserComponent } from './views/admin/user/list-user/list-user.component';
import { LayoutComponent } from './views/layout/layout.component';
import { CheckEmailComponent } from './views/user/check-email/check-email.component';
import { UserInfoComponent } from './views/admin/user/user-info/user-info.component';
import { AddSensorTypeComponent } from './views/admin/sensortypes/add-sensor-type/add-sensor-type.component';
import { ListSensorTypeComponent } from './views/admin/sensortypes/list-sensor-type/list-sensor-type.component';
import { NotificationsComponent } from './views/user/notifications/notifications.component';
import { EditSensorTypeComponent } from './views/admin/sensortypes/edit-sensor-type/edit-sensor-type.component';
/**
 * ............. services .............
 */
import { AuthGuardService } from './guards/auth-guard.service';
import { AuthService } from './services/auth.service';
import { DataService } from './services/data.service';
import { DeviceService } from './services/device.service';
import { UserService } from './services/user.service';
import { ProjectService } from './services/admin/project.service';
import { AdminDeviceService } from './services/admin/adminDevice.service';
import { AdminUserService } from './services/admin/admin_user.service';
import { SensorTypeService } from './services/admin/sensortypes.service';

@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    DashboardComponent,
    SidebarComponent,
    AuthenticateComponent,
    LoginComponent,
    SignupComponent,
    ProfileComponent,
    AddDeviceComponent,
    EditDeviceComponent,
    ListDevicesComponent,
    ListProjectComponent,
    AddSensorComponent,
    EditSensorComponent,
    ListSensorsComponent,
    AddUserComponent,
    EditUserComponent,
    ListUserComponent,
    LayoutComponent,
    CheckEmailComponent,
    UserInfoComponent,
    AddSensorTypeComponent,
    ListSensorTypeComponent,
    RealtimeComponent,
    HistoryComponent,
    NotificationsComponent,
    EditSensorTypeComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    NgxGaugeModule
  ],
  providers: [
    AuthGuardService,
    AuthService,
    DataService,
    DeviceService,
    UserService,
    ProjectService,
    AdminDeviceService,
    AdminUserService,
    SensorTypeService,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
