import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms'
import { AdminDeviceService } from 'src/app/services/admin/adminDevice.service';
import { SensorTypeService } from 'src/app/services/admin/sensortypes.service';

@Component({
  selector: 'app-edit-device',
  templateUrl: './edit-device.component.html',
  styleUrls: ['./edit-device.component.scss']
})
export class EditDeviceComponent implements OnInit {
  error;
  sensortypes;
  device;
  edited_data;
  loading = false;
  constructor(
    private adminDeviceService: AdminDeviceService,
    private SensorTypeService: SensorTypeService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.SensorTypeService.getSensorTypes()
      .subscribe((data: any) => {
        console.log("server sensors .......", data);
        this.loading = false;
        this.sensortypes = data;
      },
        (err) => {
          console.log(err);
        }
      );

    this.adminDeviceService.getDviceById(this.route.snapshot.params['device_id'])
      .subscribe((device: any) => {
        this.edited_data = device;
        this.device = device;
        console.log("server device............", device);
      },
        (err) => {
          console.log(err);
        });
  }

  onSubmit(form: NgForm) {
    this.error = null;
    var data = this.device;
    if (data._owner && data._project && data.sensortypes) {
      data.sensortypes = data.sensortypes.map(sensortype => {
        return {
          upper_limit: sensortype.upper_limit.$numberDecimal,
          lower_limit: sensortype.lower_limit.$numberDecimal,
          sensor_type: sensortype.sensor_type._id
        }
      });
      console.log("submit.........", data);
      data._owner = data._owner._id;
      data._project = data._project._id;

      this.loading = true;
      var device_id = this.route.snapshot.params['device_id']
      this.adminDeviceService.updateDviceById(device_id, data)
        .subscribe((data: any) => {
          this.loading = false;
        },
          (err) => {
            console.log(err);
            this.loading = false;
          }
        );
      this.router.navigateByUrl('admin/device/realtime/' + device_id);
    } else {
      this.error = "All Fields are required";
    }
  }

  is_in_device_sensor_types(sensortype) {
    var inside = false;
    this.device.sensortypes.forEach(element => {
      if (element.sensor_type._id === sensortype._id)
        inside = true
    });
    return inside
  }

  remove_from_sensortypes(id) {
    this.device.sensortypes.splice(id, 1);
  }

  add_to_sensortypes(sensortype) {
    this.device.sensortypes.push({
      sensor_type: sensortype,
      upper_limit: {
        $numberDecimal: 0
      },
      lower_limit: {
        $numberDecimal: 0
      }
    });
  }
}
