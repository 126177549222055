import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NgForm } from '@angular/forms'
import { SensorTypeService } from 'src/app/services/admin/sensortypes.service';

@Component({
  selector: 'app-edit-sensor-type',
  templateUrl: './edit-sensor-type.component.html',
  styleUrls: ['./edit-sensor-type.component.scss']
})
export class EditSensorTypeComponent implements OnInit {
  error;
  sensortype;
  edited_data;
  loading = false;

  constructor(
    private SensorTypeService: SensorTypeService,
    private router: Router,
    private route: ActivatedRoute,
  ) { }

  ngOnInit() {
    this.loading = true;
    this.SensorTypeService.getSensorType(this.route.snapshot.params['sensor_id'])
      .subscribe((data: any) => {
        this.loading = false;
        this.sensortype = data;
        console.log(data);
      },
        (err) => {
          console.log(err);
        }
      );
  }


  onSubmit(f) {
    this.loading = true;
    this.SensorTypeService.updateSensorTypeById(this.sensortype._id, f.value)
      .subscribe((data) => {
        this.loading = false;
        console.log(data);
        console.log(this.loading, this.sensortype);
      },
        (err) => {
          console.log(err);
        });
  }
}
