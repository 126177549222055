import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { DashboardComponent } from './views/dashboard/dashboard.component';
import { RealtimeComponent } from './views/dashboard/realtime/realtime.component';
import { HistoryComponent } from './views/dashboard/history/history.component';

import { AuthenticateComponent } from './views/user/authenticate/authenticate.component';
import { ProfileComponent } from './views/user/profile/profile.component';
import { ListProjectComponent } from './views/admin/project/list-project/list-project.component';

import { AuthGuardService } from './guards/auth-guard.service';
import { LoginComponent } from './views/user/login/login.component';
import { SignupComponent } from './views/user/signup/signup.component';
import { LayoutComponent } from './views/layout/layout.component';
import { CheckEmailComponent } from './views/user/check-email/check-email.component';
import { ListDevicesComponent } from './views/admin/device/list-devices/list-devices.component';
import { ListUserComponent } from './views/admin/user/list-user/list-user.component';
import { UserInfoComponent } from './views/admin/user/user-info/user-info.component';
import { RoleGuardService } from './guards/role-guard.service';
import { AddDeviceComponent } from './views/admin/device/add-device/add-device.component';
import { ListSensorTypeComponent } from './views/admin/sensortypes/list-sensor-type/list-sensor-type.component';
import { AddSensorTypeComponent } from './views/admin/sensortypes/add-sensor-type/add-sensor-type.component';
import { EditSensorTypeComponent } from './views/admin/sensortypes/edit-sensor-type/edit-sensor-type.component';

import { NotificationsComponent } from './views/user/notifications/notifications.component';
import { EditDeviceComponent } from './views/admin/device/edit-device/edit-device.component';


const routes: Routes = [
  {
    path: 'authenticate',
    component: AuthenticateComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'signup', component: SignupComponent }
    ]
  },
  {
    path: 'check-your-email',
    component: CheckEmailComponent,
  },
  {
    path: "admin",
    canActivate: [AuthGuardService, RoleGuardService],
    component: LayoutComponent,
    children: [
      {
        path: 'projects',
        component: ListProjectComponent
      },
      {
        path: 'devices/:project_id',
        component: ListDevicesComponent
      },
      {
        path: 'device',
        component: DashboardComponent,
        children: [
          {
            path: 'realtime/:id',
            component: RealtimeComponent
          },
          {
            path: 'history/:id',
            component: HistoryComponent
          }
        ]
      },
      {
        path: 'add_device',
        component: AddDeviceComponent,
      },
      {
        path: 'device/edit/:device_id',
        component: EditDeviceComponent,
      },
      {
        path: 'sensor_types',
        component: ListSensorTypeComponent
      },
      {
        path: 'sensor_types/edit/:sensor_id',
        component: EditSensorTypeComponent
      },
      {
        path: 'add_sensor_types',
        component: AddSensorTypeComponent
      },
      {
        path: 'users',
        component: ListUserComponent
      },
      {
        path: 'user/:user_id',
        component: UserInfoComponent
      },
    ]
  },
  {
    path: 'dashboard',
    component: LayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      {
        path: 'profile',
        component: ProfileComponent
      },
      {
        path: 'notifications',
        component: NotificationsComponent
      },
      {
        path: 'device',
        component: DashboardComponent,
        children: [
          {
            path: 'realtime/:id',
            component: RealtimeComponent
          },
          {
            path: 'history/:id',
            component: HistoryComponent
          }
        ]
      },
    ]
  },
  {
    path: '**',
    redirectTo: '/dashboard/profile',
    pathMatch: 'full'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
