import { Component, OnInit } from '@angular/core';
import { AdminUserService } from 'src/app/services/admin/admin_user.service';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-list-user',
  templateUrl: './list-user.component.html',
  styleUrls: ['./list-user.component.scss']
})
export class ListUserComponent implements OnInit {
  error;
  users;

  constructor(
    private adminUserService: AdminUserService,
  ) { }

  ngOnInit() {
    this.getUsers();
  }

  addUser(form: NgForm) {
    this.error = undefined;
    this.adminUserService.addUser(form.value)
      .subscribe((data: any) => {
        this.getUsers();
      },
        (err) => {
          this.error = "project name must be unique."
        }
      );
  }

  getUsers() {
    this.adminUserService.getUsers()
      .subscribe((data: any) => {
        this.users = data;
      },
        (err) => {
          console.log(err);
        }
      );
  }
}
