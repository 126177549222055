import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  error;
  constructor(
    private authService: AuthService,
    private router: Router
  ) { }

  ngOnInit() {
  };

  onSubmit(form: NgForm) {
    var data = form.value
    if (data.email && data.password) {
      this.authService.login(data)
        .subscribe((data: any) => {
          this.authService.setSession(data);
          this.router.navigateByUrl('/');
        },
          (err) => {
            this.error = "incorrect email or password"
          }
        );
    }
  }

}
