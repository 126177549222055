import { Component, OnInit } from '@angular/core';
import { UserService } from 'src/app/services/user.service';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  user;
  constructor(
    private userService: UserService,
    private authService: AuthService,
    private router: Router,
  ) { }

  ngOnInit() {
    this.userService.getMe()
      .subscribe((data: any) => {
        console.log(data);
        this.user = data;
      },
        (err) => {
          console.log(err);
        }
      );
  }

  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/authenticate/login');
  }
}
