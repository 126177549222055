import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class AdminUserService {
    private project_url = environment.api_url + '/admin/users/';
    constructor(
        private http: HttpClient,
    ) { }

    getUsers() {
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get<any>(this.project_url, { headers });
    }

    getUser(ussr_id) {
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.get<any>(this.project_url + ussr_id, { headers });
    }

    addUser(data) {
        var token = `JWT ${localStorage.getItem('id_token')}`
        const headers = new HttpHeaders({ 'Authorization': token });
        return this.http.post<any>(this.project_url, data, { headers });
    }
}
